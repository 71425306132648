// extracted by mini-css-extract-plugin
export var header = "header-module--header--On7Cj";
export var header_animation_left = "header-module--header_animation_left--cW7VH";
export var logo = "header-module--logo--U6OGT";
export var header_animation_right = "header-module--header_animation_right--vjfam";
export var text = "header-module--text--ESZn1";
export var header_banner = "header-module--header_banner--Ul6lE";
export var content = "header-module--content--CRxN-";
export var container = "header-module--container--bIO6E";
export var is_hidden_desktop = "header-module--is_hidden_desktop--uJD8Q";
export var is_hidden_mobile = "header-module--is_hidden_mobile--5dWPB";
export var menu = "header-module--menu--COtvJ";
export var active = "header-module--active--iiMcQ";
export var btns = "header-module--btns--YMw58";
export var btn_login = "header-module--btn_login--vFXy+";
export var btn_full = "header-module--btn_full--lF1sq";
export var btn_demo = "header-module--btn_demo--dwKcS";
export var btn_contact = "header-module--btn_contact--VzSjr";
export var logo_wrapper = "header-module--logo_wrapper--Z-eDq";
export var langswitch_mobile = "header-module--langswitch_mobile--DQ3j3";