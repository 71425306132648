import React from 'react'
import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Link from '../../components/Common/Link'
import List from '../../components/Common/List'
import Typography from '../../components/Common/Typography'

const BlagodarnostKlientamZaIdei: React.FC = () => {
  return (
    <>
      <SEO
        title="Спасибо клиентам: SIGNAL совершенствует ПО под запросы клиентов"
        description="Команда SIGNAL благодарит 100 строительных компаний за идеи и отзывы. Совершенствуем SIGNAL DOCS, TOOLS, DASHBOARD благодаря запросам наших клиентов!"
      />
      <NewsSectionBlock date="13.01.2025">
        <Typography variant="h1" color="blue">
          {`Благодарим наших клиентов за идеи,\nкоторые помогают улучшать SIGNAL\u00A0DOCS`}
        </Typography>
        <Typography variant="body1">
          Коллеги и друзья, приветствуем вас в 2025 году!
        </Typography>
        <Typography variant="body1">
          Команда SIGNAL благодарна каждому, кто выбрал цифровые сервисы SIGNAL
          для управление своими проектами.
        </Typography>
        <Typography variant="body1">
          Новый год - время подводить итоги и сказать слова благодарности нашим
          клиентам - тем, кто помогает нам делать продукт лучше.
        </Typography>
        <Typography variant="body1">
          За прошедший год свыше 100 российских строительных компаний
          подсоединились к нашей платформе SIGNAL&nbsp;DOCS.
        </Typography>
        <Typography variant="body1">
          Каждый день более 1500 пользователей работают в SIGNAL&nbsp;DOCS,
          отмечая удобство и надежность нашего сервиса.
        </Typography>
        <Typography variant="body1">
          С каждым из клиентов мы составляем список пожеланий по доработкам.
          Далее мы перечислим лишь немногие функции и первые компании кто
          подробно описал запрос:
        </Typography>
        <List>
          <li>
            По запросу компании Alean Development Systems появились функции
            Привязка замечаний к элементам модели и Фильтрация замечаний по
            пользовательским атрибутам.
          </li>
          <li>
            Прикрепление вложений в замечания без предварительной загрузки в
            DOCS, реализованное по просьбе компании Атриум Строй.
          </li>
          <li>
            Пользовательские атрибуты на файлы и папки, транслируемые в
            Замечания по запросу от компании Sminex. Также по их просьбе под
            конец года появились Пакеты передачи.
          </li>
          <li>
            Создание проекта на основе шаблона по запросу от Level Group и X5
            Retail Group.
          </li>
          <li>
            Мобильная версия DOCS, работающая оффлайн, для работы с чертежами в
            местах без интернета (подвалы, паркинги), критичность которой
            подсветили ГК ТОЧНО.
          </li>
        </List>
        <Typography variant="body1">
          Всего за 2024 год SIGNAL реализовал свыше 200 обновлений и улучшений в
          сервисах SIGNAL TOOLS, DOCS, DASHBOARD, INSPECTION и HUB.
        </Typography>
        <Typography variant="body1">
          Кроме того, для удобства клиентов мы запустили бота техподдержки в
          Telegram (
          <Link
            href="https://t.me/sgnl_support_bot"
            rel="nofollow noreferrer"
            target="_blank"
          >
            @sgnl_support_bot
          </Link>
          ), что позволяет оперативно получать ответы от профильных
          специалистов.
        </Typography>
        <Typography variant="body1">
          Мы стремимся к тому, чтобы SIGNAL был не только инструментом для
          управления проектами, но и надежным партнером для строительных
          компаний. Мы слышим запросы наших клиентов и адаптируем продукт под их
          нужды. Ваша поддержка и ваши идеи вдохновляют нас на развитие и
          совершенствование.
        </Typography>
        <Typography variant="body1">
          Спасибо, что выбираете SIGNAL – отечественное программное обеспечение,
          которое находит отклик у профессионалов строительной отрасли. Мы
          уверены, что вместе достигнем ещё больших высот!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BlagodarnostKlientamZaIdei
