import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, PageProps } from 'gatsby'

import { ILocalesMap } from '../i18n/types'
import { DEFAULT_LOCALE, DEFAULT_SITE_TITLE } from '../data'
import { GlobalContext, ILocationState } from '../context/GlobalContext'
import { LocaleContext } from '../context/LocaleContext'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../assets/styles/global.scss'

interface ISiteData {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

interface IPageContext {
  locale: string
  locales: ILocalesMap
  t: Record<string, string>
}

const Layout: React.FC<
  PageProps<Record<string, unknown>, IPageContext, ILocationState>
> = ({
  children,
  location,
  pageContext: { locale = DEFAULT_LOCALE, locales = {}, t = {} },
}) => {
  const { site } = useStaticQuery<ISiteData>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [width, setWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  )
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowSizeChange)

      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])
  const isDesktop = width > 1280

  return (
    <LocaleContext.Provider
      value={{
        locale,
        locales,
        t,
      }}
    >
      <GlobalContext.Provider
        value={{
          location,
          siteTitle: site?.siteMetadata?.title || DEFAULT_SITE_TITLE,
          isDesktop,
          region: process.env.GATSBY_APP_REGION ?? '',
        }}
      >
        <Header />
        <main className="app-main">{children}</main>
        <Footer />
      </GlobalContext.Provider>
    </LocaleContext.Provider>
  )
}

export default Layout
