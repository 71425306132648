// extracted by mini-css-extract-plugin
export var section = "pages-module--section--YBgcd";
export var anchor_link = "pages-module--anchor_link--gmnte";
export var bannerSubtitle = "pages-module--bannerSubtitle--gZbBp";
export var bannerTitle = "pages-module--bannerTitle--+UYOW";
export var bannerDigits = "pages-module--bannerDigits--iCTnU";
export var value__module = "pages-module--value__module--GudDC";
export var value__module_wrap = "pages-module--value__module_wrap--NNFmT";
export var value__module_strip = "pages-module--value__module_strip--7FpFv";
export var active = "pages-module--active--2NHWw";
export var value__module_list = "pages-module--value__module_list--yN7UL";
export var value__demoClass = "pages-module--value__demoClass--akgnk";
export var bim_description = "pages-module--bim_description--OWH1k";
export var bim__demoClass = "pages-module--bim__demoClass--6eGKA";
export var digital_description = "pages-module--digital_description--T-hfk";
export var digital__demoClass = "pages-module--digital__demoClass--EydoA";
export var privacy_statement__title = "pages-module--privacy_statement__title--7MIfP";
export var privacy_statement__list = "pages-module--privacy_statement__list--7ZVEb";
export var imageBlock = "pages-module--imageBlock--uyh2a";
export var image = "pages-module--image--rLXWV";
export var contact = "pages-module--contact--fmb6o";
export var contact_container = "pages-module--contact_container--1vFwe";
export var contact_section_wrap = "pages-module--contact_section_wrap--gY9Js";
export var contact_title = "pages-module--contact_title--ckaod";
export var contact_textTitle = "pages-module--contact_textTitle--wv0Eu";
export var contact_text = "pages-module--contact_text--shIKW";
export var contact_list = "pages-module--contact_list--dxro2";
export var contact_btn = "pages-module--contact_btn--ravqQ";
export var contact_map = "pages-module--contact_map--sm-4c";
export var trapWrapper = "pages-module--trapWrapper--Nmp8e";
export var trap = "pages-module--trap--1Yzn4";
export var brand_section = "pages-module--brand_section--TTkN4";
export var brand_wrapper = "pages-module--brand_wrapper--kze7f";
export var brand_content = "pages-module--brand_content--RG9Wt";
export var brand_description = "pages-module--brand_description--FTwze";
export var brand_logos = "pages-module--brand_logos--WXxFN";
export var brand_row = "pages-module--brand_row--hrIiQ";
export var brand_text = "pages-module--brand_text--5DZLf";
export var brand_link = "pages-module--brand_link--NPJGS";
export var brand_line = "pages-module--brand_line--2OJMa";
export var brand_color_gradient = "pages-module--brand_color_gradient--O4+CH";
export var brand_logo = "pages-module--brand_logo--Ldb6P";
export var brand_logo_white = "pages-module--brand_logo_white--blrfV";
export var brand_logo_shadow = "pages-module--brand_logo_shadow--R700F";
export var brand_color = "pages-module--brand_color--i98bM";
export var brand_color_title = "pages-module--brand_color_title--9Q8wg";
export var brand_color_small = "pages-module--brand_color_small--NXCp4";
export var brand_color_large = "pages-module--brand_color_large--+ghf6";