import React from 'react'
import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import * as s from '../../pages/news.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const NovyjGod2025PozdravleniePartneram: React.FC = () => {
  return (
    <>
      <SEO
        title="Новый год 2025: Поздравление от Технического директора SIGNAL о будущем цифровизации строительной отрасли"
        description="С наступающим Новым годом! Технический директор SIGNAL, Александр Попов, делится своим поздравлением и видением будущего цифровизации в строительной сфере. Узнайте о наших достижениях, успехах проектов и нововведениях, которые ждут нас в 2025 году. Партнеры и коллеги, давайте вместе преодолевать новые горизонты и достигать небывалых высот!"
      />
      <NewsSectionBlock date="25.12.2024">
        <Typography variant="h1" color="blue">
          Поздравление с Новым годом 2025
          <br /> от Технического директора SIGNAL Александра Попова
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/news/novyj-god-2025-pozdravlenie-partneram/image-1.png"
            alt="Поздравление с Новым годом 2025 от Технического директора SIGNAL
          Александра Попова"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Дорогие партнеры и коллеги, дорогие друзья!
        </Typography>
        <Typography variant="body1">
          Поздравляю вас с наступающим Новым годом!
        </Typography>
        <Typography variant="body1">
          С каждым годом мы вместе открываем новые горизонты и преодолеваем
          значимые рубежи, становимся свидетелями того, как технологии меняют
          облик строительной отрасли, и 2025 год обещает стать не менее значимым
          и вдохновляющим!
        </Typography>
        <Typography variant="body1">
          Мы с гордостью можем сказать, что наши проекты по созданию и развитию
          среды общих данных уже превосходят зарубежные аналоги по таким
          критериям, как функциональность, надежность, удобство и стоимость.
          Успех DOCS превысил все ожидания, и мы не намерены останавливаться. В
          ближайшее время планируем успешно реализовать модули BUILD и ID
          (исполнительная документация). Этот успех — результат нашего
          совместного труда, безупречного профессионализма и стремления к
          постоянному совершенствованию. Уверен, что в 2025 году мы продолжим
          поднимать планку еще выше!
        </Typography>
        <Typography variant="body1">
          Развитие цифровизации в России — это не просто тренд или
          необходимость, это наше дело и призвание, которому мы все отдаем свое
          время, энергию и идеи. Мы создаем инновационные решения, которые
          помогают строителям, проектировщикам и инженерам работать более
          эффективно и продуктивно. И SIGNAL, будучи одним из участников сферы,
          вносит значимый вклад в общий успех!
        </Typography>
        <Typography variant="body1">
          Пусть наступающий год принесет нам всем новые возможности, идеи и
          крепкие партнерские связи. Я верю, что вместе мы сможем достичь
          небывалых высот в цифровизации строительной отрасли в нашей стране.
        </Typography>
        <Typography variant="body1">
          Берегите себя и своих близких. Желаю здоровья, счастья и вдохновения
          на новые достижения. Давайте встретим новый год с оптимизмом и
          готовностью к новым свершениям! Пусть каждый почувствует силу команды,
          поддержку коллег и радость от совместных побед.
        </Typography>
        <Typography variant="body1">
          С наилучшими пожеланиями,
          <br /> Технический директор SIGNAL
          <br /> Александр Попов
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default NovyjGod2025PozdravleniePartneram
