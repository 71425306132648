import React from 'react'

import Section from '../Common/Section'
import Container from '../Common/Container'
import Typography from '../Common/Typography'
import ModuleItem from './ModuleItem'
import { Button } from '../Common/Button'
import ContactForm from '../contact-form'

import { ModuleName } from '../../types'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import { toLink } from '../../utils'
import { downloadPriceListLink } from '../../data'

import * as s from './Modules.module.scss'

const ModulesSection: React.FC = () => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  const [modal, setModal] = React.useState<string>()
  const titleModal =
    region === AppRegionEnum.Ru ? t('Request demo') : t('Learn more')

  return (
    <>
      <Section bgColor="dark">
        <Container>
          <Typography variant="h2" color="white" size={40}>
            {t('Discover SIGNAL modules')}
          </Typography>
          <div className={s.content}>
            <div className={s.column}>
              <ModuleItem
                moduleName={ModuleName.Docs}
                text={[t('_docs__lead1'), t('_docs__lead2'), t('_docs__lead3')]}
                subtitle={t('_docs__subtitle')}
                image={{
                  id:
                    region === AppRegionEnum.Ru
                      ? 'signalDocsPhone'
                      : 'signaxDocsPhone',
                  alt: t('_docs__image__title'),
                  title: t('_docs__image__title'),
                }}
              />
              <ModuleItem
                moduleName={ModuleName.Inspection}
                subtitle={t('_inspection_subtitle')}
                text={[
                  t('_inspection_lead1'),
                  t('_inspection_lead2'),
                  t('_inspection_lead3'),
                ]}
                image={{
                  id:
                    region === AppRegionEnum.Ru
                      ? 'signalInspectionPhone'
                      : 'signaxInspectionPhone',
                  alt: t('_inspection_image_alt'),
                  title: t('_inspection_image_title'),
                }}
              />
            </div>
            <div className={s.column}>
              <ModuleItem
                moduleName={ModuleName.Tools}
                subtitle={t('_tools_subtitle')}
                text={[t('_tools_lead1'), t('_tools_lead2'), t('_tools_lead3')]}
                image={{
                  id: region === AppRegionEnum.Ru ? 'tools' : 'toolsEn',
                  alt: t('_tools__image_alt'),
                  title: t('_tools__image_title'),
                }}
                modulePosition="left"
              />
              <ModuleItem
                moduleName={ModuleName.Dashboard}
                subtitle={t('_dashboard_subtitle')}
                text={[
                  t('_dashboard_lead1'),
                  t('_dashboard_lead2'),
                  t('_dashboard_lead3'),
                ]}
                image={{
                  id:
                    region === AppRegionEnum.Ru
                      ? 'signalDashboardPhone'
                      : 'signaxDashboardPhone',
                  alt: t('_dashboard_image_alt'),
                  title: t('_dashboard_image_title'),
                }}
                isModules
              />
            </div>
          </div>
          <div className={s.buttons}>
            <Button
              size="large"
              onClick={() =>
                setModal(
                  region === AppRegionEnum.Ru
                    ? t('Request a demo of Signal')
                    : t('Learn more')
                )
              }
              title={titleModal}
            >
              {titleModal}
            </Button>
            {region === AppRegionEnum.Ru && (
              <Button
                size="large"
                color="white"
                onClick={() => toLink(downloadPriceListLink)}
                title={t('Download price list')}
              >
                {t('Download price list')}
              </Button>
            )}
          </div>
        </Container>
      </Section>
      {modal && (
        <ContactForm
          onClose={() => setModal(undefined)}
          title={modal}
          requestType={region === AppRegionEnum.Ru ? 'Demo' : 'Learn more'}
        />
      )}
    </>
  )
}

export default ModulesSection
