import React from 'react'
import {
  youtubeLink,
  youtubeLinkUs,
  telegramLink,
  telegramLinkUs,
  linkedinLinkUs,
  whatsappLinkUs,
  vkLink,
  instagramLinkUs,
  facebookLinkUs,
  tiktokLinkUs,
  snapchatLinkUs,
} from '../../data'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import FBIcon from '../../svg/fb-icon'
import YoutubeIcon from '../../svg/youtube-icon'
import TelegramIcon from '../../svg/telegram-icon'
import SnapchatIcon from '../../svg/snapchat-icon'
import LinkedinIcon from '../../svg/linkedin-icon'
import VkIcon from '../../svg/vk-icon'
import InstIcon from '../../svg/inst-icon'
import WhatsappIcon from '../../svg/whatsapp-icon'
import TikTokIcon from '../../svg/tiktok-icon'
import * as s from './social-links.module.sass'
import cn from 'classnames'

interface ILinksItem {
  title: string
  link: string
  hideOnRuReg?: boolean
  hideOnUsReg?: boolean
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
}

const getLinks = (region: string): ILinksItem[] => [
  {
    title: 'VK',
    link: vkLink,
    Icon: VkIcon,
    hideOnUsReg: true,
  },
  {
    title: 'Whatsapp',
    link: whatsappLinkUs,
    hideOnRuReg: true,
    Icon: WhatsappIcon,
  },
  {
    title: 'Linkedin',
    link: linkedinLinkUs,
    hideOnRuReg: true,
    Icon: LinkedinIcon,
  },
  {
    title: 'Youtube',
    link: AppRegionEnum.Us === region ? youtubeLinkUs : youtubeLink,
    Icon: YoutubeIcon,
  },
  {
    title: 'Instagram',
    link: instagramLinkUs,
    Icon: InstIcon,
    hideOnRuReg: true,
  },
  {
    title: 'Facebook',
    link: facebookLinkUs,
    Icon: FBIcon,
    hideOnRuReg: true,
  },
  {
    title: 'TikTok',
    link: tiktokLinkUs,
    Icon: TikTokIcon,
    hideOnRuReg: true,
  },
  {
    title: 'Telegram',
    link: AppRegionEnum.Us === region ? telegramLinkUs : telegramLink,
    Icon: TelegramIcon,
  },
  {
    title: 'Snapchat',
    link: snapchatLinkUs,
    Icon: SnapchatIcon,
    hideOnRuReg: true,
  },
]

const SocialLinks: React.FC = () => {
  const { region } = React.useContext(GlobalContext)
  return (
    <div className={cn('inline-flex flex-wrap', s.container)}>
      {(region === AppRegionEnum.Ru
        ? getLinks(region).filter(link => !link.hideOnRuReg)
        : getLinks(region).filter(link => !link.hideOnUsReg)
      ).map(({ title, link, Icon }) => (
        <a
          href={link}
          title={title}
          key={link}
          rel="nofollow noreferrer"
          target="_blank"
          className={s.item}
        >
          <Icon className="w-100 h-100" />
        </a>
      ))}
    </div>
  )
}

export default SocialLinks
